<template>
  <div id="UnSubscribe">
    <div class="container padingTop">
      <div class="col-lg-6 offset-lg-3">
        <div class="textBlock">
          <h4>Vaše e-mailová adresa:</h4>
          <h5>dominik.nenkovice@gmail.com</h5>
          <h4>Byla odstraněna z databáze.</h4>
          <p>V případě, že se budete chtít znovu přihlásit k odběru novinek, využijte formulář na hlavní stránce: <a href="">maratonhudby.cz</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnSubscribePage"
}
</script>

<style scoped>
h4 {
  font-family: "Transcript Pro";
}
h5 {
  font-family: "Transcript Mono";
}
p {
  font-family: "Transcript Mono";
}
a {
  font-family: "Transcript Mono";
  color: white;
  text-decoration: underline;
}
.padingTop {
  padding-top: 14%;
}
#UnSubscribe {
  height: 100vh;
  background-color: #e7e7e6;
}
.textBlock {
  color: white;
  padding: 2em 3em;
  border-radius: 10px;
  background-color: #152326;
}

</style>